import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

const sortField = [
  // {
  //   value: undefined,
  //   label: "正常",
  // },
  {
    value: "NEW",
    label: "最新",
  },
  {
    value: "HOT",
    label: "最热",
  },
];

const { map: sortFieldMap, setOps: sortFieldOps } = mapHelper.setMap(sortField);
const kindsList = [
  {
    value: "7,8,9,10",
    label: "三会一课",
  },
  {
    value: "11",
    label: "主题党日",
  },
  {
    value: "12",
    label: "红管家",
  },
  {
    value: "13",
    label: "党务公开",
  },
  {
    value: "14",
    label: "居务公开",
  },
  {
    value: "22",
    label: "财务公开",
  },
  {
    value: "7,8,9,10,11,12,13,14,22",
    label: "全部",
  },
];
const { map: kindsListMap, setOps: kindsListOps } = mapHelper.setMap(kindsList);
const secKindsList = [
  {
    value: "7",
    label: "支委会",
  },
  {
    value: "8",
    label: "党员大会",
  },
  {
    value: "9",
    label: "党小组会",
  },
  {
    value: "10",
    label: "党课",
  },
];
const { map: secKindsListMap, setOps: secKindsListOps } =
  mapHelper.setMap(secKindsList);
const kindsFormList = [
  {
    value: "7,8,9,10",
    label: "三会一课",
  },
  {
    value: "11",
    label: "主题党日",
  },
  {
    value: "12",
    label: "红管家",
  },
  {
    value: "13",
    label: "党务公开",
  },
  {
    value: "14",
    label: "居务公开",
  },
  {
    value: "22",
    label: "财务公开",
  },
];
const { map: kindsFormListMap, setOps: kindsFormListOps } =
  mapHelper.setMap(kindsFormList);
export {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
  kindsListMap,
  kindsList,
  secKindsList,
  secKindsListMap,
  kindsFormList,
};
